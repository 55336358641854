.left_arrow {
  width: 44px;
  height: 44px;
  right: 0px;
  top: 66px;
}

.right_arrow {
  width: 44px;
  height: 44px;
  right: -20px;
  top: 66px;
}
